import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import BringHisKingdom from "../img/icon/bring-his-kingdom.inline.svg";
import ExaltChrist from "../img/icon/exalt-christ.inline.svg";
import HostHisPresence from "../img/icon/host-his-presence.inline.svg";
import TransformOurWorld from "../img/icon/transform-our-world.inline.svg";
import anime from "animejs/lib/anime.es.js";

const OurMission = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeBg: "",
    };
  }

  mouseEnter(bg) {
    this.setState({
      activeBg: bg,
    });
    anime({
      targets: ".gh-home-icon",
      opacity: 0.5,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100),
    });
  }

  mouseLeave() {
    this.setState({
      activeBg: "",
    });
    anime({
      targets: ".gh-home-icon",
      opacity: 1,
      easing: "cubicBezier(0.115, 0.61, 0.255, 1)",
      delay: anime.stagger(100),
    });
  }

  render() {
    const { data } = this.props;
    const { activeBg } = this.state;
    return (
      <section
        className={`gh-our-mission ${
          this.activeBg !== "" ? `gh-our-mission--${activeBg}` : ""
        }`}
      >
        <div className={activeBg === "prayer" ? "prayer active" : "prayer"}>
          <Img fluid={data.headerImage1.childImageSharp.fluid} />
        </div>
        <div className={activeBg === "restoration" ? "restoration active" : "prayer"}>
          <Img fluid={data.headerImage2.childImageSharp.fluid} />
        </div>
        <div className={activeBg === "discipleship" ? "discipleship active" : "prayer"}>
          <Img fluid={data.headerImage3.childImageSharp.fluid} />
        </div>
        <div
          className={activeBg === "community" ? "community active" : "prayer"}
        >
          <Img fluid={data.headerImage4.childImageSharp.fluid} />
        </div>
        <div
          className={activeBg === "ministry" ? "ministry active" : "prayer"}
        >
          <Img fluid={data.headerImage5.childImageSharp.fluid} />
        </div>

        <div className="container">
          <h2>
            Our <span>Mission</span>
          </h2>
          <p>
            Gracehouse is a Christian Church in Logan. We are a community
            passionate about Jesus. We exist as a hub for prayer and worship, restoration, equipping, community mobilisation and ministry mobilisation.
          </p>
          <div className="gh-home-icons">
            <div
              className="gh-home-icon gh-home-icon--translate-right"
              onMouseEnter={() => this.mouseEnter("prayer")}
              onMouseLeave={() => this.mouseLeave()}
            >
              <ExaltChrist />
              <h2>Hub of Prayer and Worship</h2>
            </div>
            <div
              className="gh-home-icon"
              onMouseEnter={() => this.mouseEnter("restoration")}
              onMouseLeave={() => this.mouseLeave()}
            >
              <HostHisPresence />
              <h2>Hub of Restoration</h2>
            </div>
            <div
              className="gh-home-icon gh-home-icon--translate-left"
              onMouseEnter={() => this.mouseEnter("discipleship")}
              onMouseLeave={() => this.mouseLeave()}
            >
              <BringHisKingdom />
              <h2>Hub of Equipping</h2>
            </div>
            <div
              className="gh-home-icon"
              onMouseEnter={() => this.mouseEnter("community")}
              onMouseLeave={() => this.mouseLeave()}
            >
              <TransformOurWorld />
              <h2>Hub of Community Mobilisation</h2>
            </div>
            <div
              className="gh-home-icon gh-home-icon--rotate"
              onMouseEnter={() => this.mouseEnter("ministry")}
              onMouseLeave={() => this.mouseLeave()}
            >
              <TransformOurWorld />
              <h2>Hub of Ministry Mobilisation</h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        headerImage1: file(relativePath: { eq: "worship2.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage2: file(relativePath: { eq: "kids2.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage3: file(relativePath: { eq: "aidan.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage4: file(relativePath: { eq: "tahlia.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerImage5: file(relativePath: { eq: "vicky.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => <OurMission data={data} />}
  />
);
